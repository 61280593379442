@import 'utilities/media_queries';

$medium-font-size: 16px;
$small-font-size: 12px;
$extra-small-font-size: 10px;

@mixin typography-class($font-size, $line-height, $font-weight, $font-family, $letter-spacing) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  font-family: $font-family;
  letter-spacing: $letter-spacing;
}

@mixin app-typography {
  $h1-large-font-size: 42px;
  $h4-medium-font-size: 18px;

  h1,
  .app-h1 {
    @include typography-class($h1-large-font-size, normal, normal, Inter, 0.75px);
  }

  h2,
  .app-h2 {
    @include typography-class(36px, normal, normal, Inter, 0.65px);
  }

  h3,
  .app-h3 {
    @include typography-class(24px, normal, normal, Inter, 0.43px);
  }

  .app-h3-medium-weight {
    @include typography-class(24px, normal, 500, Inter, 0.43px);
  }

  h4,
  .app-h4 {
    @include typography-class($h4-medium-font-size, normal, normal, Inter, 0.32px);
  }

  .app-h4-medium-weight {
    @include typography-class($h4-medium-font-size, normal, 500, Inter, 0.32px);
  }

  .app-h4-bold {
    @include typography-class($h4-medium-font-size, normal, 600, Inter, 0.32px);
  }

  h5,
  .app-h5 {
    @include typography-class(16px, normal, normal, Inter, 0.29px);
  }

  .app-h5-medium-weight {
    @include typography-class(16px, normal, 500, Inter, 0.29px);
  }

  .app-h5-bold {
    @include typography-class(16px, normal, 600, Inter, 0.29px);
  }

  .app-body {
    @include typography-class(14px, normal, normal, Inter, 0.25px);
  }

  .app-body-medium-weight {
    @include typography-class(14px, normal, 500, Inter, 0.25px);
  }

  .app-body-bold {
    @include typography-class(14px, normal, 600, Inter, 0.25px);
  }

  .app-numbers-l-bold {
    @include typography-class(18px, normal, 600, SF Mono, 0.25px);
  }

  .app-numbers-medium-weight {
    @include typography-class(14px, normal, 500, SF Mono, 0.25px);
  }

  .app-numbers {
    @include typography-class(16px, normal, 400, SF Mono, 0.25px);
  }

  .app-numbers-bold {
    @include typography-class(16px, normal, 600, SF Mono, 0.25px);
  }

  .app-numbers-medium {
    @include typography-class(14px, normal, 400, SF Mono, 0.25px);
  }

  .app-numbers-medium-size-bold {
    @include typography-class(14px, normal, 600, SF Mono, 0.25px);
  }

  .app-numbers-small {
    @include typography-class(12px, normal, 400, SF Mono, 0.25px);
  }

  .app-numbers-small-bold {
    @include typography-class(12px, normal, 600, SF Mono, 0.25px);
  }

  .app-body-small {
    @include typography-class($small-font-size, normal, normal, Inter, 0.22px);
  }

  .app-body-extra-small {
    @include typography-class($extra-small-font-size, normal, normal, Inter, 0.22px);
  }

  .app-body-small-const {
    @include typography-class($small-font-size, normal, normal, Inter, 0.22px);
  }

  .app-body-small-medium-weight {
    @include typography-class($small-font-size, normal, 500, Inter, 0.22px);
  }

  .app-body-small-medium-weight-const {
    @include typography-class($small-font-size, normal, 500, Inter, 0.22px);
  }

  .app-body-small-bold {
    @include typography-class($small-font-size, normal, 600, Inter, 0.22px);
  }

  .app-caption {
    @include typography-class(10px, normal, normal, Inter, 0.22px);
  }

  .app-section-subtitle {
    @include typography-class(16px, normal, 600, Inter, 0.22px);
  }

  .app-badge {
    @include typography-class(10px, 16px, 500, Inter, 0.2px);
  }

  @include media-query(xxl) {
    $h1-large-font-size: 32px;
    $h4-medium-font-size: 16px;
    $small-font-size: 10px;

    h1,
    .app-h1 {
      @include typography-class($h1-large-font-size, normal, normal, Inter, 0.75px);
    }
    h4,
    .app-h4 {
      @include typography-class($h4-medium-font-size, normal, normal, Inter, 0.32px);
    }

    .app-h4-medium-weight {
      @include typography-class($h4-medium-font-size, normal, 500, Inter, 0.32px);
    }
    .app-body-small {
      @include typography-class($small-font-size, normal, normal, Inter, 0.22px);
    }

    .app-body-small-medium-weight {
      @include typography-class($small-font-size, normal, 500, Inter, 0.22px);
    }
  }
}
