@import 'colors';

$background-color: $pale-grey-blue;
$white-background-color: $white;

// Sidenav variables

$sidenav-extended-width: 256px;
$sidenav-collapsed-width: 109px;

// Color

$text-color-basic: $dark-blue-color;
$text-color-secondary: $white;
$text-color-primary: $almost-black;
$text-color-additional-info: $light-greyish;

$primary-button-color: $primary-blue;
$primary-button-hover-color: $primary-dark-blue;
$primary-button-disabled-color: $bright-blue;
$primary-button-focus-color: $primary-darkest-blue;

$button-box-shadow-color: $grey-with-opacity;
$settings-button-border-color: $pale-grey;
$settings-delete-button-color: $red;

$view-more-dots-color: $primary-blue;
$blue-header-color: $primary-blue;
$sub-header-color: $tank-grey;

$sidenav-item-color: $grey-color;
$sidenav-item-active-color: $primary-blue;
$sidenav-new-label-font-size: 8px;
$sidenav-new-label-font-weight: 600;

$view-more-link-color: $grey-color;
$view-more-link-hover-color: $dark-blue-color;

$warning-color: $red;
$danger-text-color: $yellow;
$success-text-color: $light-green-color;
$cancel-button-color: $light-grey;
$cancel-button-border-color: $dark-grey;
$back-button-color: $darkish-grey;

$highlight-color: $white-grey;

$invisible-button-text-color: $dark-grey;
$invisible-button-background-color: $white-background-color;
$dark-blue-background-color: $dark-blue-color;
$secondary-light-background: $light-grey-brownish;

$strength-neutral-color: $dark-grey;
$line-bar-neutral-color: $light-grey;
$line-bar-weak-color: $red;
$line-bar-medium-color: $yellow;
$line-bar-great-color: $light-green-color;

$dark-blue-text-color: $dark-blue-color;
$modal-header-color: $dark-blue-color;
$modal-close-icon-color: $almost-black;

$preview-modal-icon-color: $white;
$preview-modal-toolbar-bg-color: $black-ocean;
$preview-modal-toolbar-separator-color: $dark-grey-red;

$placeholder-text-color: $grey-color;
$placeholder-text-color-darker: $secondary-dark-grey;

$table-header-color: $pale-grey-blue;
$table-borders-color: $pale-grey;
$table-order-icon-size: 10px;
$table-scrollbar-thumb-color: $medium-grey-with-opacity;

$borders-color: $light-grey;
$dark-border-color: $dark-blue-color;
$disabled-fill-grey: $almost-white;
$disabled-text-grey: $dark-grey;
$disabled-border-color: $light-cool-grey;

$grid-tooltip-color: $granite-grey;
$grid-control-border-color: $borders-color;
$grid-control-text-color: $almost-black;
$grid-control-icon-color: $almost-black;
$grid-control-action-icon-color: $primary-blue;
$grid-total-row-border-color: $darkish-grey;
$grid-column-icon-color: $raven-grey;
$grid-selection-checkbox-border-color: $light-grey;
$grid-selection-checkbox-color: $white;
$grid-hover-color: $icy-blue;
$grid-dragging-border-color: $grey-brownish;
$grid-dragging-box-shadow-color: $grey-brownish-with-opacity;
$grid-droppable-line-color: $primary-blue;
$grid-group-icon-color: $light-grey;
$grid-error-bg-color: $pale-red-color;
$grid-error-color: $pale-dark-red;
$grid-tooltip-font-size: 12px;
$grid-tooltip-line-height: 16px;
$grid-indeterminate-checkbox-background: $light-grey;
$grid-indeterminate-checkbox-mark-color: $white;
$grid-duplicate-column-color: $raven-grey;
$grid-duplicate-column-separator-color: $light-silver;
$grid-link-color: $blue;
$grid-action-shadow-color: $blue-grey-with-opacity;
$grid-action-border-color: $dark-blue-with-opacity;
$grid-big-tooltip-bg-color: $black-red;
$grid-big-tooltip-accent-bg-color: $grey-red;
$grid-footer-bg-color: $pale-grey-blue;

$financial-chart-border-color: $light-silver;
$financial-chart-grid-lines-color: $grey;
$financial-chart-legend-text-color: $dark-blue-color;
$financial-chart-projected-bullet-color: $white;
$financial-tooltip-bg-color: $black-brown;
$financial-tooltip-text-color: $white;
$financial-tooltip-total-border-color: $black-red;
$financial-tabs-border-color: $light-grey-with-opacity;
$financial-tabs-active-border-color: $black;
$financial-tabs-text-color: $dark-blue-color;

$disabled-item-color: $grey-color;
$checkbox-edit-button-color: $grey-color;
$tabs-border-bottom-color: $grey-color;
$tabs-active-border-bottom-color: $almost-black;
$tabs-beta-sign-border-color: $primary-blue;
$tabs-beta-sign-background-color: $primary-blue;
$tabs-beta-sign-color: $text-color-secondary;

$edit-icon-color: $yellow;
$checkmark-icon-color: $light-green-color;

$button-progress-color: $darker-green;
$grant-progress-color: $darker-green;

$tooltip-shadow-color: $blue-grey-with-opacity;
$tooltip-border-color: $dark-blue-with-opacity;

$add-avatar-border-color: $primary-blue;

$box-border-color: $primary-blue;
$box-background-color: $light-blue-with-opacity;
$box-background-light-color: $very-light-blue;
$box-title-icon-size: 20px;

$link-color: $primary-blue;
$link-color-hover: $sky-blue;
$welcome-cover-color: $light-grey-brownish;
$empty-state-cover-color: $light-grey-brownish;

$small-border-radius: 4px;
$border-radius: 8px;
$card-padding: 20px;
$card-padding-participant: 32px;
$select-height: 40px;
$select-button-dropdown-color: $darker-grey;
$select-disabled-option-text-color: $grey-brownish;
$select-dark-dropdown-icon-color: $black;

$autocomplete-search-icon-color: $dark-grey;

$numbers-font-family: 'SF Mono';
$text-font-family: 'Inter';
$input-font-size: 14px;
$input-padding: 16px;
$input-clear-button-color: $almost-black;
$input-clear-icon-color: $white;
$calculate-button-color: $primary-blue;
$grid-font-size: 12px;
$grid-total-font-size: 14px;
$grid-group-icon-size: 8px;

$general-info-dropdown-background-color: $white-background-color;
$dropdown-hover-color: $white-grey;
$general-info-dropdown-background-size: 25px;

$credentials-tfa-code-hint-color: $dark-blue-color;
$onboarding-tfa-text-color: $very-dark-grey;
$tfa-code-background-color: $light-grey-blue;

$policy-text-color: $dark-blue-color;
$policy-link-color: $blue;

$onboarding-bg-color: $light-grey-brownish;
$onboarding-side-gradient: linear-gradient(270deg, $dark-grey-blue 0%, $dark-black-blue 100%);
$onboarding-control-border-color: $pale-grey;
$onboarding-control-checked-color: $primary-blue;
$onboarding-control-checked-bg-color: $primary-blue-with-opacity;
$onboarding-control-note-color: $dark-grey;
$onboarding-info-icon-color: $mid-grey-blue;
$onboarding-select-icon-color: $almost-black;
$onboarding-tooltip-bg-color: $granite-grey;
$onboarding-back-color: $darkish-grey;
$onboarding-optional-color: $dark-grey;
$onboarding-upload-border-color: $light-grey;
$onboarding-upload-text-color: $dark-grey;
$onboarding-disabled-input-color: $dark-grey;
$onboarding-disabled-input-bg-color: $grey;

$participant-progress-percent-color: $grey-brownish;
$participant-vested-color: $chinese-blue;
$participant-unconditional-vested-color: $teal;
$participant-unvested-color: $bright-gray;

$black-grey-color: $black-grey;

/** Widgets **/
$widget-background: $light-sky-blue;

/** Stepper variables start **/

$stepper-item-border-width: 1px;
$stepper-divider-width: 2px;
$stepper-small-bullet-height: 10px;
$stepper-bullet-height: 14px;
$stepper-divider-height: 16px;
$stepper-item-height: 32px;
$stepper-item-width: 32px;
$vertical-stepper-item-height: 36px;
$vertical-stepper-item-width: 36px;

$stepper-item__active-background-color: $primary-blue;
$stepper-item__active-border-color: $primary-blue;
$stepper-item__active__color: $primary-blue;

$stepper-item__pending-background-color: $white;
$stepper-item__pending-border-color: $darker-grey;
$stepper-item__pending__color: $darker-grey;
$stepper-bullet__pending__color: $light-grey;

$stepper-item__success-background-color: $light-green-color;
$stepper-item__success-border-color: $green-color;

$stepper-item__complete-background-color: $primary-blue;
$stepper-item__complete-color: $white;

$stepper-item-hover-color: $primary-blue;

$horizontal-stepper-pending-bg-color: $light-silver;
$horizontal-stepper-active-bg-color: $primary-blue;
/** Stepper variables end **/

/** Multiselect variables start **/
$category-children-number-background: $blue-with-opacity;
$category-children-number-color: $sky-darker-blue;
$category-chevron-color: $grey-brownish;
$color-toggle-border-color: $darker-grey;
$highlighted-option-background: $pale-grey-blue;
/** Multiselect variables end **/

/** Multiselect inside frame (stakeholders) start **/
$multiselect-delete-hover-color: $almost-black;
$multiselect-delete-color: $darkish-grey;
$multiselect-item-background-color: $grey;
/** Multiselect inside frame (stakeholders) end **/

/** Import audit start **/
$file-size-color: $darker-grey;
$file-remove-cross-color: $darker-grey;
$radio-button-border-color: $darker-grey;
$radio-button-checked-fill-color: $primary-blue;
/** Import audit end **/

/** External connection details start **/
$external-source-list-border-color: $pale-grey;
$external-source-logo-background-color: $pale-grey-blue;
$external-source-logo-border-color: $pale-grey;
$external-source-image-background-color: $medium-grey-brownish;
$external-source-footer-color: $darkish-grey;
$external-source-footer-border-color: $pale-grey;
$external-source-toggle-border-color: $pale-grey;
$external-source-subtitle-color: $mid-grey-blue;
$external-source-warning-border-color: $orange;
$external-source-warning-background-color: $orange-with-opacity;
$external-source-warning-sign-background-color: $orange;
$external-source-connection-color: $light-green-color;
$external-source-connection-background-color: $medium-light-green-color;
$external-source-read-more-color: $primary-blue;

/** External connection details end **/

/** Scenario modeling start **/
$scenario-list-card-subtitle-color: $darkish-grey;
/** Scenario modeling end **/

$new-badge-background-color: $yellow;
$new-badge-color: $dark-cool-grey;
$increase-color: $green-grey;
$decrease-color: $darker-red;
$zero-percent-growth-color: $orange;

$calendar-border-color: $light-grey-blue;
$calendar-selected-item-border-color: $sky-darker-blue;
$calendar-selected-backgournd-color: $lightes-blue;

$button-height: 40px;
// tabs height is needed for absolute positions buttons in loans&convertibles
$tabs-height: $button-height;

$white-background: $white;

$blue-grey-color: $blue-grey;
$grey-background: $grey;
$note-color: $pale-grey-brownish;

$table-expand-icon: $dark-blue-color;

$docs-notification-separator: $light-grey;
$doc-breadcrumbs-color: $grey-brownish;
$docs-onboarding-banner-background: $deep-blue;
$docs-onboarding-banner-link: $blue-middle;
$docs-size-color: $secondary-dark-grey;

$datepicker-toggle-icon-size: $general-info-dropdown-background-size;
$datepicker-toggle-calendar-icon-size: 16px;
$datepicker-calendar-icon-color: $sky-blue;

/** Paginator variables start **/
$pagination-text-color: $mid-grey-blue;
$paginator-height: 40px;
$arrow-icon-size: 8px;
$arrow-icon-font-size: 14px;
$dropdown-padding: 4px;
$dropdown-height: 24px;
$dropdown-width: 45px;
$search-icon-size: 20px;

$divider-line-color: $pale-grey;

$chart-button-border-color: $light-grey;

/** Scenario modeling**/
$disabled-scenario-button-color: $light-grey;
$scenario-trash-color: $light-grey;
$scenario-total-color: $widget-background;
$scenario-delete-button-color: $cool-grey;

$tooltip_theme_dark_background_color: $dark-blue-color;

$input-addon-background: $grey;
$input-addon-text-basic-color: $black-blue;
$input-addon-text-color: $white;

$menu-item-hover-color: $lightes-blue;

/** File upload **/
$medium-icon-size: 16px;
$large-icon-size: 32px;
$disabled-upload: $grey-brownish;
$action-button-color: $cool-grey;
$action-button-stroke: $white;

/** User groups **/
$overview-small-indicators-color: $secondary-dark-grey;

/** Filter variables */
$filter-badge-background: $primary-blue;
$filter-cancel-button-color: $primary-blue;
$filter-collapse-icon-color: $dark-blue-color;
$checkbox-border: $dark-grey;

$invite-background-color: $light-grey-brownish;

$tab-text-color: $lighter-grey;
$tab-active-text-color: $almost-black-grey;

/** Form */
$invalid-input-bg-color: $pale-red-color;
$valid-input-bg-color: $pale-green-color;

$table-category-input-bg-color: $white;
$table-blue-border-color: $light-clear-blue;

/** Self Onboarding Progress Home Page*/
$expansion-grey-color: $smoky;
$header-subtitle-color: $mid-grey-blue;
$progress-checkbox-color-checked: $green-color;
$progress-checkbox-color-unchecked: $light-silver;
$progress-optional-color: $smoky;
$progress-percent-grey-color: $mid-grey-blue;
$progress-bar-bg-color: $light-grey-reddish;
$progress-bar-gradient: linear-gradient(270deg, $green-color 0%, $bright-green 100%);

/** Auth pages */
$auth-bg-color: $light-grey-brownish;
$auth-side-gradient: linear-gradient(270deg, $inky-blue 0%, $inky-black 100%);
$auth-quote-color: $medium-blue;
$auth-back-color: $darkish-grey;
$auth-anchor-color: $blue;
$auth-password-strength-color: $pale-grey;

$interim-screen-gradient: linear-gradient(270deg, $dark-grey-blue 0%, $dark-black-blue 100%);

/** Login page */
$suggestion-text-color: $dark-grey;
