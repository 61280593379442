$white: #fff;
$almost-white: #fafafa;
$pale-grey-blue: #f3f5f7;
$light-grey-blue: #e5e7e9;
$light-grey-reddish: #ececec;
$pale-grey: #dddddd;
$grey-color: #9ca3af;
$grey-with-opacity: rgba(0, 0, 0, 0.25);
$medium-grey-with-opacity: rgba(0, 0, 0, 0.3);
$light-grey: #d9d9d9;
$light-grey-brownish: #f7f7f7;
$medium-grey-brownish: #f9f9f9;
$pale-grey-brownish: #aaaaaa;
$grey-brownish: #b2b2b2;
$tank-grey: #72757e;
$darker-grey: #979797;
$dark-grey: #798186;
$secondary-dark-grey: #757575;
$light-greyish: #747677;
$darkish-grey: #919394;
$blue-grey: #495965;
$very-dark-grey: #363636;
$almost-black: #1a1a1a;
$black-blue: #121b21;
$white-grey: #f4f0ff;
$grey: #f2f2f2;
$cool-grey: #939a9f;
$light-cool-grey: #e1e2eb;
$dark-cool-grey: #545a64;
$mid-grey-blue: #717374;
$granite-grey: #616161;
$light-grey-with-opacity: rgba(0, 0, 0, 0.2);
$raven-grey: #6d7078;
$grey-brownish-with-opacity: rgba(116, 116, 116, 0.3);
$bright-gray: #e4e6ee;
$light-silver: #d8d8d8;
$black-brown: #111111;
$black-red: #242424;
$lighter-grey: rgba(26, 26, 26, 0.4);
$almost-black-grey: rgba(26, 26, 26, 1);
$black: #000000;
$dark-blue-with-opacity: rgba(3, 17, 37, 0.1);
$blue-grey-with-opacity: rgba(46, 91, 255, 0.1);
$black-red: #333333;
$grey-red: #434343;
$black-ocean: #282a2b;
$dark-grey-red: #4a4a4a;
$smoky: #a9acb0;
$black-grey: #60656c;

$dark-blue-color: #031125;
$dark-blue-grey-color: #03112570;
$dark-blue-light-grey-color: #031125b3;
$dark-grey-blue: #0d214e;
$dark-black-blue: #1a2e55;
$inky-blue: #0d314e;
$inky-black: #1e3b73;

$yellow: #fbc162;
$orange: #ffba49;
$orange-with-opacity: rgba(255, 186, 73, 0.2);

$primary-blue: #567fee;
$primary-blue-with-opacity: #567fee1a;
$primary-dark-blue: #4f75dc;
$primary-darkest-blue: #204fcd;
$deep-blue: #4931d4;
$bright-blue: #83a5ff;
$light-blue: #cbd8ea;
$light-clear-blue: #d2eafe;
$blue-middle: #b1c4f7;
$blue: #3182ce;
$blue-with-opacity: rgba(64, 144, 255, 0.2);
$lightes-blue: #eef2ff;
$light-blue-with-opacity: #2e5bff1a;
$light-sky-blue: #f0f8ff;
$very-light-blue: #fbfbfe;
$icy-blue: #e9f3ff;
$chinese-blue: #3955a0;
$medium-blue: #a5d6fe;

$sky-blue: #4da1f0;
$sky-darker-blue: #4090ff;
$dark-blue: #4882d5;

$red: #ff4e4e;
$pale-dark-red: #eb4747;
$light-red-color: #f3626a;
$darker-red: #a33737;
$pale-red-color: #fef6f6;

$teal: #12b0ca;
$light-green-color: #00bf71;
$medium-light-green-color: #f0f9eb;
$green-color: #4cb34c;
$green-grey: #469b5c;
$darker-green: #00a676;
$bright-green: rgba(130, 196, 109, 0.5);
$pale-green-color: #f8fcf8;

$main-page-loading-background: #051d39;
