@import 'utilities/variables';

@mixin app-buttons {
  $button-smallest-width: 100px;
  $button-width: 144px;
  $medium-button-width: 200px;
  $button-box-shadow: 0 0 10px 0 $button-box-shadow-color;

  .button__smallest {
    width: $button-smallest-width;
    height: $button-height;
  }

  .button__small {
    width: $button-width;
    height: $button-height;
  }

  .button__medium {
    width: $medium-button-width;
    height: $button-height;
  }

  .base-button,
  .base-outline-button {
    height: $button-height;
    border-radius: 5px;
  }

  .primary-button {
    border-radius: 5px;
    background-color: $primary-button-color;
    color: $text-color-secondary;
    box-sizing: border-box;
    border: none;
    min-height: 40px;

    &:focus {
      outline: none;
      border: solid 1px $primary-button-focus-color;
      color: $text-color-secondary;
      box-shadow: none !important;
    }

    &:hover {
      box-shadow: $button-box-shadow;
      background-color: $primary-button-hover-color;
      color: $text-color-secondary;
    }

    &:disabled {
      background-color: $primary-button-disabled-color;
      cursor: not-allowed !important;
      box-shadow: none;
    }
  }

  .filter-button {
    border-radius: 5px !important;
    background-color: $white;
    font-weight: bold;

    &:hover {
      border-color: $primary-button-color;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .reverse-button {
    border-radius: 5px;
    background-color: $text-color-secondary;
    color: $primary-button-color;
    box-sizing: border-box;
    border: 1px $primary-button-color solid;

    &:focus {
      outline: none;
      box-shadow: $button-box-shadow;
      background-color: $text-color-secondary;
      color: $primary-button-color;
      border: 1px $primary-button-color solid;
    }

    &:hover {
      box-shadow: $button-box-shadow;
      background-color: $text-color-secondary;
      color: $primary-button-color;
      border: 1px $primary-button-color solid;
    }

    &:disabled {
      background-color: $primary-button-disabled-color;
      cursor: not-allowed !important;
      box-shadow: none;
    }
  }

  .settings-button {
    height: 40px;
    border: solid 1px $settings-button-border-color;
    border-radius: $s-gap;
    padding: $s-gap;
  }

  .transparent-pale-button {
    background-color: $white;
    color: $disabled-text-grey;
    width: 100px;
    border: none;
  }

  .transparent-button {
    border-radius: 5px;
    background-color: $white;
    color: $primary-button-color;
    border-color: $primary-button-color;
    font-weight: bold;
    margin-right: $s-gap;

    &:focus {
      outline: none;
      color: $primary-button-color;
      border-radius: 5px;
      box-shadow: $button-box-shadow;
      border: solid 2px $primary-button-color;
    }

    &:hover {
      color: $primary-button-color;
      border-radius: $s-gap;
      box-shadow: $button-box-shadow;
    }
  }

  .tertiary-button {
    border-radius: 5px;
    border: solid 1px $text-color-basic;
    background-color: $white;

    &:hover {
      border: solid 1px $text-color-basic;
      box-shadow: $button-box-shadow;
    }

    &:focus {
      box-shadow: $button-box-shadow;
      border: solid 2px $text-color-basic;
    }

    &:disabled {
      cursor: not-allowed !important;
      &:hover {
        box-shadow: none;
      }
    }
  }

  .secondary-button {
    color: white;
    border-radius: 5px;
    border: solid 1px $warning-color;
    background-image: linear-gradient(to right, #ff6868, $warning-color 100%);
    width: 94px;

    &:hover {
      color: white;
      border: solid 1px $warning-color;
      box-shadow: $button-box-shadow;
      background-image: linear-gradient(to right, $warning-color, #ff6868 100%);
    }

    &:focus {
      color: white;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      border: solid 2px $white;
      background-color: $warning-color;
    }
  }

  .base-button {
    width: 144px;
    background-color: $primary-button-color;
    color: white;
    border: none;

    &:hover {
      box-shadow: $button-box-shadow;
      background-color: $primary-button-hover-color;
      color: $text-color-secondary;
    }

    &:active {
      color: $text-color-secondary;
      border-color: $primary-button-focus-color;
      box-shadow: none;
    }

    &:focus {
      color: $text-color-secondary;
      border-color: $primary-button-focus-color;
      box-shadow: none;
    }

    &:disabled {
      background-color: $primary-button-disabled-color;
      cursor: not-allowed !important;
      box-shadow: none;
    }

    &__medium {
      width: 200px;
    }
  }

  .base-outline-button {
    width: 200px;
    border: solid 1px $dark-blue-color;
    background-color: $white;
    color: $dark-blue-color;

    &:hover {
      border-color: $dark-blue-color;
      color: $dark-blue-color;
      font-weight: bold;
    }
    &:focus {
      border-color: $dark-blue-color;
      color: $dark-blue-color;
    }
  }

  .anchor {
    color: $link-color;

    &:hover {
      cursor: pointer;
      color: $link-color-hover;
    }

    &--with-decoration {
      text-decoration: underline;
    }
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }

  .control-button {
    border-radius: 5px;
    border: unset;
    padding: 10px 1$s-gap;
    height: 40px;

    span {
      line-height: 1;
    }

    &__icon {
      margin-right: 12px;
      @include icon-size(20px);
    }

    &__black {
      background-color: $dark-blue-color;
      color: white;
    }
  }

  .save-button {
    width: 65px;
  }

  .invisible-button {
    color: $invisible-button-text-color;
    background-color: $invisible-button-background-color;

    margin: 0;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 100px;

    &:focus {
      outline: none;
      border: solid 1px $cancel-button-border-color;
      box-shadow: none !important;
    }

    &:hover {
      border: solid 1px $cancel-button-border-color;
    }
  }

  .cancel-button {
    margin: 0 11px 0 0;
    padding: 10px 49px;
    border: none;
    border-radius: 5px;
    height: 40px;
    width: 144px;
    background-color: $cancel-button-color;
    color: $text-color-basic;

    &:focus {
      outline: none;
      border: solid 1px $cancel-button-border-color;
      box-shadow: none !important;
    }

    &:hover {
      border: solid 1px $cancel-button-border-color;
    }
  }

  .button-reset-styles {
    border: none;
    background: none;
    padding: unset;
  }
}
