@import 'utilities/variables';

// Colors
$success: $light-green-color;
$danger: $light-red-color;

// Tabs variables
$tabs-link-active-color: rgba(26, 26, 26, 1);
$tabs-link-color: rgba(26, 26, 26, 0.4);
$tabs-link-active-border-bottom-color: rgba(26, 26, 26, 1);
$tabs-link-padding: 0px 16px 8px 0px;
$tabs-border-bottom-width: 1px;

.tabs li.is-active a {
  border-bottom-width: 4px;
}

.tabs li:not(.is-active) span {
  margin-bottom: 3px;
}

// Dropdown menu
$dropdown-menu-min-width: 100px;

$card-radius: $border-radius;

@import 'bulma/bulma';
