$breakpoints: (
  xs: 0px,
  sm: 577px,
  md: 769px,
  lg: 992px,
  xl: 1025px,
  xxl: 1201px,
  xxxl: 1321px,
  xxxxl_lower: 1439px,
  xxxxl: 1441px,
);

@mixin media-query($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media only screen and (max-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
