@import 'utilities/variables';

@mixin app-modal-common-styles {
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    margin: -32px;
    padding-bottom: 16px;
    margin-bottom: -16px;
    background-color: $modal-header-color;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .close-icon {
      height: 18px;
      width: 18px;
    }
  }

  .divider {
    margin: 16px -32px 32px;
    background-color: rgba(165, 214, 254, 0.2);
    height: 1px;
  }

  .modal-body {
    margin-bottom: 32px;

    &__avatar {
      display: flex;
      justify-content: center;
    }

    &__form {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      ::ng-deep input {
        width: 100%;
        padding-left: $input-padding;
        padding-right: $input-padding;
      }

      .modal-select {
        width: 100%;
        height: 40px;
      }

      label {
        font-weight: 700;
      }

      .form-field {
        position: relative;
        width: 100%;
      }

      .form-group {
        width: 100%;
        display: flex;
        flex-flow: row;
      }

      .inline-form-field {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .checkbox-field {
        height: 40px;
      }

      .checkbox-wrapper {
        position: relative;
        padding-left: 35px;
      }

      .checkbox-wrapper input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 21px;
        width: 21px;
        top: 1px;
        left: 0;
        z-index: 1;
      }

      .checkbox-wrapper label {
        position: relative;
        color: $almost-black;
      }

      .checkbox-wrapper label:before {
        content: '';
        position: absolute;
        top: 0;
        left: -35px;
        height: 21px;
        width: 21px;
        border-radius: 4px;
        border: solid 2px $borders-color;
        background-color: #ffffff;
      }

      .centered-checkbox label:before {
        top: 0;
      }

      .checkbox__edit-button {
        color: $checkbox-edit-button-color;
        text-decoration: underline;
        margin-left: 8px;
        margin-top: 2px;
        font-size: 14px;
        transition: all 0.3s;

        &:hover {
          cursor: pointer;
          color: $almost-black;
        }
        &_invalid {
          color: $warning-color;
        }
        &_valid {
          color: $checkbox-edit-button-color;
        }
      }

      .checkbox-wrapper input:checked ~ label:after {
        content: '';
        position: absolute;
        top: 4px;
        left: -27px;
        width: 6px;
        height: 10px;
        border: solid #031125;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .centered-checkbox input:checked ~ label:after {
        top: 3px;
      }
    }
  }

  .stakeholder-name {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;

    .is-primary-button {
      margin-left: auto;
    }

    .primary-button {
      margin-left: auto;
    }
  }

  .failed-request-message {
    color: $warning-color;
    margin-top: 16px;
  }
}
