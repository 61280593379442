@use "ag-grid-community/styles" as ag;
@import 'utilities/common';
@import 'layout/layout_utils';

@include ag.grid-styles(
  (
    theme: wevestr,
    --ag-background-color: $white,
    --ag-header-background-color: $pale-grey-blue,
    --ag-border-color: $pale-grey,
    --ag-row-border-color: $pale-grey,
    --ag-range-selection-border-color: $primary-blue,
    --ag-foreground-color: $dark-blue-color,
    --ag-borders: solid 1px,
    --ag-borders-row: solid 1px,
    --ag-borders-critical: solid 0px,
    --ag-grid-size: 4px,
    --ag-row-height: calc(var(--ag-grid-size) * 14),
    --ag-row-hover-color: $grid-hover-color,
    --ag-header-height: calc(var(--ag-grid-size) * 10.25),
    --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 8),
    --ag-cell-widget-spacing: calc(var(--ag-grid-size) * 2),
    --ag-font-family: $text-font-family,
    --ag-font-size: $grid-font-size,
    --ag-borders-input: solid 0px,
    --ag-input-focus-border-color: $primary-blue,
    --ag-selected-row-background-color: transparent,
    --ag-icon-font-code-checkbox-checked: '',
    --ag-icon-font-code-checkbox-unchecked: '',
    --ag-icon-font-code-grip: url(~assets/icons/drag_menu.svg),
    --ag-icon-font-code-arrows: url(~assets/icons/drag_menu.svg),
  )
);

.ag-theme-wevestr {
  .ag-root-wrapper {
    border-radius: 8px;
    letter-spacing: 0.22px;
  }

  .ag-root-wrapper-body {
    border-bottom: calc(var(--ag-grid-size) * 10) solid $pale-grey-blue;
  }

  .ag-header-group-cell-label {
    font-weight: bold;
    justify-content: center;
    text-transform: capitalize;
  }

  .ag-header {
    border-bottom: var(--ag-borders-row) var(--ag-row-border-color);
  }

  &.ag-pinned-column-border {
    .ag-pinned-left-cols-container,
    .ag-pinned-left-header {
      box-shadow: 3px 0 6px 0 rgba(167, 168, 170, 0.31);
      z-index: 1;
    }
  }

  .ag-cell-value,
  .ag-header-cell-text {
    text-overflow: initial;
  }

  .ag-cell-focus {
    border-radius: 2px;
  }

  .ag-pinned-left-cols-container {
    .ag-cell-value {
      text-overflow: ellipsis;
    }
  }

  .ag-numeric-cell {
    font-family: $numbers-font-family;
    text-align: right;
  }

  .ag-right-aligned-cell {
    text-align: right;
  }

  .ag-font-medium-bold {
    font-weight: 500;
  }

  .ag-font-semi-bold {
    font-weight: 600;
  }

  .ag-without-padding-and-border {
    padding: 0;
    border: none !important;
  }

  .ag-cell-inline-editing {
    height: calc(var(--ag-row-height) - 1px);
  }

  .ag-cell-inline-editing:has(wevestr-input-editor):has(.invalid-form-field) {
    border: 1px solid transparent !important;
  }

  .ag-tooltip {
    box-shadow: none;
    border-radius: 4px;
    background-color: $grid-tooltip-color;
    color: $white;
    border: none;
    padding: $s-gap;
  }

  .ag-tooltip::before {
    content: '';
    position: absolute;
    top: -4px;
    left: 8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 4px solid $grid-tooltip-color;
  }

  .ag-total-row {
    box-shadow: 0px -1px 0px 0px $grid-total-row-border-color;
    z-index: 2;

    .ag-cell-last-left-pinned {
      font-size: $grid-total-font-size;
    }
  }

  .ag-row:has(.ag-cell-below-highlighted, .ag-cell-short-below-highlight) {
    z-index: 3;
  }

  .ag-row:has(.ag-cell-above-highlighted) {
    z-index: 1;

    &:not(.ag-row-first) {
      box-shadow: 0 1px 0 0 $grid-droppable-line-color inset;
    }

    &.ag-row-first {
      box-shadow: 0 2px 0 0 $grid-droppable-line-color inset;
    }

    .ag-cell-last-left-pinned::before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      left: -5px;
      top: -4px;
      background-color: $grid-droppable-line-color;
      border-radius: 50%;
    }
  }

  .ag-cell-above-highlighted {
    box-shadow: 0 -1px 0 0 $grid-droppable-line-color;
  }

  .ag-cell-below-highlighted {
    box-shadow: 0 2px 0 0 $grid-droppable-line-color;

    &.ag-cell-last-left-pinned::before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      left: -5px;
      bottom: -5px;
      background-color: $grid-droppable-line-color;
      border-radius: 50%;
    }
  }

  .ag-cell-short-below-highlight {
    &:not(.ag-cell-last-left-pinned) {
      box-shadow: 0 2px 0 0 $grid-droppable-line-color;
    }

    &.ag-cell-last-left-pinned::after {
      content: '';
      width: calc(100% - 51px);
      height: 2px;
      position: absolute;
      left: 51px;
      bottom: -3px;
      background-color: $grid-droppable-line-color;
    }

    &.ag-cell-last-left-pinned::before {
      content: '';
      width: 6px;
      height: 6px;
      position: absolute;
      left: 51px;
      bottom: -5px;
      background-color: $grid-droppable-line-color;
      border-radius: 50%;
    }
  }

  .ag-cell-block-highlighted {
    border-radius: $small-border-radius 0 0 $small-border-radius;
    border: solid 1px $grid-droppable-line-color !important;
    box-shadow: 0 0 0 1px $grid-droppable-line-color inset;
  }

  .ag-selection-checkbox {
    --ag-cell-widget-spacing: 4px;
  }

  .ag-checkbox-input-wrapper {
    --ag-icon-size: 20px;
    border: 1px solid $grid-selection-checkbox-border-color;
    background-color: $grid-selection-checkbox-color;
    border-radius: 4px;
  }

  .ag-checkbox-input-wrapper.ag-checked {
    background-image: url(~assets/icons/check.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10.5px 8px;
  }

  .ag-row:has(.ag-checkbox-indeterminate) {
    .ag-checkbox-input-wrapper:not(.ag-checked) {
      background-color: $grid-indeterminate-checkbox-background;
      background-position: center;
      background-repeat: no-repeat;
    }

    .ag-checkbox-input-wrapper:not(.ag-checked)::after {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      right: 0;
      margin: 0 auto;
      width: 60%;
      height: 2px;
      background: $grid-indeterminate-checkbox-mark-color;
      border-radius: 4px;
      transition: background 0.4s;
    }
  }

  .ag-drag-handle {
    visibility: hidden;
  }

  .ag-row-hover,
  .ag-row-dragging {
    .ag-drag-handle {
      visibility: visible;
    }
  }

  .ag-row-dragging {
    opacity: 0.3;
  }

  wevestr-input-editor {
    width: 100%;
  }

  .ag-no-padding {
    padding: 0;
  }

  .ag-invalid-cell {
    width: 100%;

    .ag-cell-value {
      height: 40px;
      line-height: 40px;
      width: 100%;
      border: 1px solid $grid-error-color;
      background-color: $grid-error-bg-color;
      border-radius: $small-border-radius;
    }

    .error-message {
      color: $grid-error-color;
      text-align: left;
      line-height: 13px;
    }
  }

  .ag-duplicate-cell {
    color: $grid-duplicate-column-color;
  }

  .ag-duplicate-cell-with-separator::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - calc(var(--ag-grid-size) * 3.5 / 2));
    height: calc(var(--ag-grid-size) * 3.5);
    width: 1px;
    background-color: $grid-duplicate-column-separator-color;
  }

  &.ag-column-grouped .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
    padding-top: calc(var(--ag-header-height) * 2);
  }

  &.ag-column-grouped .ag-overlay-loading-wrapper.ag-layout-auto-height {
    z-index: 2;
    padding-top: calc(var(--ag-header-height) * 2);
  }

  .ag-link {
    color: $grid-link-color !important;
    cursor: pointer;
  }
}

app-root:has(.ag-theme-wevestr.dnd-cursor-blocked) ~ .ag-dnd-ghost {
  cursor: not-allowed;
}

.ag-dnd-ghost {
  cursor: grabbing;
  width: 228px;
  border-radius: $small-border-radius 0 0 $small-border-radius;
  box-shadow: 0 3px 4px 0 $grid-dragging-box-shadow-color;
  border: solid 1px $grid-dragging-border-color;
  --ag-icon-font-code-not-allowed: url(~assets/icons/drag_menu.svg);

  .ag-dnd-ghost-label {
    font-weight: 600;
    font-family: $text-font-family;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
