@import 'utilities/media_queries';

$l-gap: 32px;
// TODO: change 32 for xl, 24 - for l
$medium-gap: 24px;
$m-gap: 16px;
$s-gap: 8px;
$xs-gap: 4px;
$xxs-gap: 2px;

@mixin app-layout-utilities() {
  .flex {
    display: flex;
  }

  .flex-auto {
    flex: 1 1 auto;
  }

  .flex-column {
    display: flex;
    flex-direction: column !important;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-row-inline {
    display: inline-flex;
    flex-direction: row;
  }

  .flex-center {
    justify-content: center;
    align-items: center;
  }

  .align-items-center {
    align-items: center;
  }

  .align-items-flex-end {
    align-items: flex-end;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .align-self-end {
    align-self: flex-end;
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-right {
    text-align: right;
  }

  .padding-xs {
    padding: $xs-gap;
  }

  .padding-s {
    padding: $s-gap;
  }

  .padding-m {
    padding: $m-gap;
  }

  .padding-l {
    padding: $l-gap;
  }

  .padding-l-const {
    padding: $l-gap;
  }

  .padding-top-l-const {
    padding-top: $l-gap;
  }

  .padding-bottom-l-const {
    padding-bottom: $l-gap;
  }

  .padding-medium {
    padding: $medium-gap;
  }

  .padding-top-medium {
    padding-top: $medium-gap;
  }

  .padding-bottom-medium {
    padding-bottom: $medium-gap;
  }

  .padding-left-medium {
    padding-left: $medium-gap;
  }

  .padding-right-medium {
    padding-right: $medium-gap;
  }

  .margin-top-medium {
    margin-top: $medium-gap;
  }

  .margin-bottom-medium {
    margin-bottom: $medium-gap;
  }

  .margin-left-medium {
    margin-left: $medium-gap;
  }

  .margin-left-auto {
    margin-left: auto;
  }

  .margin-m {
    margin: $m-gap;
  }

  .margin-l {
    margin: $l-gap;
  }

  .margin-left-l-const {
    margin-left: $l-gap;
  }

  .margin-right-l-const {
    margin-right: $l-gap;
  }

  .padding-left-s {
    padding-left: $s-gap;
  }

  .padding-left-m {
    padding-left: $m-gap;
  }

  .padding-left-l {
    padding-left: $l-gap;
  }

  .padding-right-s {
    padding-right: $s-gap;
  }

  .padding-right-m {
    padding-right: $m-gap;
  }

  .padding-right-m-const {
    padding-right: $m-gap;
  }

  .padding-right-l {
    padding-right: $l-gap;
  }

  .padding-top-xs {
    padding-top: $xs-gap;
  }

  .padding-top-s {
    padding-top: $s-gap;
  }

  .padding-top-m {
    padding-top: $m-gap;
  }

  .padding-top-m-const {
    padding-top: $m-gap;
  }

  .padding-top-l {
    padding-top: $l-gap;
  }

  .margin-left-s {
    margin-left: $s-gap;
  }

  .margin-left-xs {
    margin-left: $xs-gap;
  }

  .margin-bottom-xs {
    margin-bottom: $xs-gap;
  }

  .margin-top-xs {
    margin-top: $xs-gap;
  }

  .margin-left-m {
    margin-left: $m-gap;
  }

  .margin-left-m-const {
    margin-left: $m-gap;
  }

  .margin-left-l {
    margin-left: $l-gap;
  }

  .margin-left-xl {
    margin-left: 64px;
  }

  .padding-bottom-xs {
    padding-bottom: $s-gap;
  }

  .padding-bottom-s {
    padding-bottom: $s-gap;
  }

  .padding-bottom-m {
    padding-bottom: $m-gap;
  }

  .padding-bottom-m-const {
    padding-bottom: $m-gap;
  }

  .padding-bottom-l {
    padding-bottom: $l-gap;
  }

  .margin-bottom-s {
    margin-bottom: $s-gap !important;
  }

  .margin-bottom-m {
    margin-bottom: $m-gap;
  }

  .margin-bottom-m-const {
    margin-bottom: $m-gap;
  }

  .margin-bottom-l {
    margin-bottom: $l-gap;
  }
  .margin-bottom-l-const {
    margin-bottom: $l-gap;
  }

  .margin-bottom-xl {
    margin-bottom: 64px !important;
  }

  .margin-top-xl {
    margin-top: 64px;
  }

  .margin-top-l {
    margin-top: $l-gap;
  }

  .margin-top-l-const {
    margin-top: $l-gap;
  }

  .margin-top-m {
    margin-top: $m-gap;
  }

  .margin-top-m-const {
    margin-top: $m-gap;
  }

  .margin-top-s {
    margin-top: $s-gap;
  }

  .margin-right-s {
    margin-right: $s-gap;
  }

  .margin-right-xs {
    margin-right: $xs-gap;
  }

  .margin-right-m {
    margin-right: $m-gap;
  }

  .margin-right-m-const {
    margin-right: $m-gap;
  }

  .margin-right-l {
    margin-right: $l-gap;
  }

  .margin-right-xl {
    margin-right: 64px;
  }

  .margin-right-xxl {
    margin-right: 128px;
  }

  .width-inherit {
    width: inherit;
  }
  .white-space-nowrap {
    white-space: nowrap;
  }

  @include media-query(xxxl) {
    $l-gap: 20px;
    $m-gap: 10px;
    .padding-m {
      padding: $m-gap;
    }
    .padding-l {
      padding: $l-gap;
    }
    .margin-l {
      margin: $l-gap;
    }
    .margin-left-l {
      margin-left: $l-gap;
    }
    .margin-right-l {
      margin-right: $l-gap;
    }
    .margin-top-l {
      margin-top: $l-gap;
    }
    .margin-bottom-l {
      margin-bottom: $l-gap;
    }
    .padding-left-l {
      padding-left: $l-gap;
    }
    .padding-right-l {
      padding-right: $l-gap;
    }
    .padding-top-l {
      padding-top: $l-gap;
    }
    .padding-bottom-l {
      padding-bottom: $l-gap;
    }

    .margin-m {
      margin: $m-gap;
    }
    .margin-left-m {
      margin-left: $m-gap;
    }
    .margin-right-m {
      margin-right: $m-gap;
    }
    .margin-top-m {
      margin-top: $m-gap;
    }
    .margin-bottom-m {
      margin-bottom: $m-gap;
    }
    .padding-left-m {
      padding-left: $m-gap;
    }
    .padding-right-m {
      padding-right: $m-gap;
    }
    .padding-top-m {
      padding-top: $m-gap;
    }
    .padding-bottom-m {
      padding-bottom: $m-gap;
    }
  }

  @include media-query(xxl) {
    $l-gap: 16px;

    .padding-l {
      padding: $l-gap;
    }
    .margin-l {
      margin: $l-gap;
    }
    .margin-left-l {
      margin-left: $l-gap;
    }
    .margin-right-l {
      margin-right: $l-gap;
    }
    .margin-top-l {
      margin-top: $l-gap;
    }
    .margin-bottom-l {
      margin-bottom: $l-gap;
    }
    .padding-left-l {
      padding-left: $l-gap;
    }
    .padding-right-l {
      padding-right: $l-gap;
    }
    .padding-top-l {
      padding-top: $l-gap;
    }
    .padding-bottom-l {
      padding-bottom: $l-gap;
    }
  }
}
