.checkbox-wrapper {
  position: relative;
  padding-left: 38px;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 21px;
  width: 21px;
  top: 1px;
  left: 0;
  z-index: 1;
}

.checkbox-wrapper label {
  position: relative;
  color: #1a1a1a;
}

.checkbox-wrapper label:before {
  content: '';
  position: absolute;
  top: 0;
  left: -38px;
  height: 21px;
  width: 21px;
  border-radius: 4px;
  border: solid 2px $borders-color;
  background-color: #ffffff;
}


.centered-checkbox label:before {
  top: 0;
}

.checkbox-wrapper input:checked ~ label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: -30px;
  width: 6px;
  height: 10px;
  border: solid #031125;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.centered-checkbox input:checked ~ label:after {
  top: 3px;
}
