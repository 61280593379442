@import 'variables';
@import 'media_queries';

@mixin icon-size($size) {
  font-size: $size;
  line-height: $size !important;
  width: $size !important;
  height: $size !important;
}

@mixin app-common {
  .red-color {
    color: $light-red-color;
  }

  .light-border {
    border: 1px solid $light-blue;
  }

  .light-border-r {
    border-right: 1px solid $light-blue;
  }

  .light-border-t {
    border-top: 1px solid $light-blue;
  }

  .light-border-b {
    border-bottom: 1px solid $light-blue;
  }

  .light-border-l-r {
    border-left: 1px solid $light-blue;
    border-right: 1px solid $light-blue;
  }

  .full-height {
    height: 100%;
  }

  .height-auto {
    height: auto;
  }

  .full-width {
    width: 100%;
  }

  .half-width {
    width: 50%;
  }

  .width-max-content {
    width: max-content;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break-word-next-line {
    overflow-wrap: break-word;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .view-more-dots {
    color: $view-more-dots-color;
    &:focus {
      outline: none;
    }
  }

  .view-more-link {
    transition: all 0.5s;
    color: $view-more-link-color;
  }

  .view-more-link:hover {
    fill: $view-more-link-hover-color;
  }

  .overflow-auto {
    overflow: auto;
  }

  .overflow-x-auto {
    overflow-x: auto;
  }

  .dark-text-color {
    color: $almost-black;
  }

  .rotate180 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .card {
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    overflow: unset;
    color: $almost-black;
  }

  .card-with-borders {
    border: 2px solid $borders-color;
    border-radius: 8px;
  }

  .welcome-card {
    width: 600px;
    min-height: 620px;
    padding: 40px;
  }

  .welcome-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100%;
    padding: 20px;

    .welcome-card-disclaimer {
      padding-bottom: 40px;
    }
  }

  .dot {
    border-radius: 50%;
    display: inline-block;
    width: 12px;
    height: 12px;
  }

  .flex-1 {
    flex: 1;
  }

  .margin-auto {
    margin: auto;
  }

  .position-fixed {
    position: fixed;
  }

  .position-sticky {
    position: sticky;
  }

  .top-0 {
    top: 0;
  }

  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute !important;
  }

  .z-index-3 {
    z-index: 3;
  }

  .side-dialog {
    margin-top: 0 !important;
    .mat-dialog-container {
      box-shadow: unset;
    }
  }

  .color-black {
    color: black;
  }

  .font-weight-medium {
    font-weight: 500;
  }

  .height-inherit {
    height: inherit;
  }

  .light-red-color {
    color: $light-red-color;
  }

  .light-green-color {
    color: $light-green-color;
  }

  .flex-33 {
    flex-grow: 1;
    width: 33%;
  }

  .flex-50 {
    flex: 1 1 33%;
  }

  .dropdown-menu {
    min-width: unset;
  }

  .dropdown-item:hover {
    background-color: $dropdown-hover-color;
  }

  .dropdown-content {
    max-height: 200px;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid $borders-color;
    min-width: 100%;
    width: fit-content;
  }

  .rounded-left-corners {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  .rounded-right-corners {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .white-space-pre-wrap {
    white-space: pre-wrap;
  }

  .inline-input {
    border: solid 1px #d2eafe;
    border-radius: 10px;
    outline: none;
    font-weight: bold;
    padding: 8px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .warning {
    color: $warning-color;
    margin-top: 4px;
  }

  .warning-color {
    color: $warning-color;
  }

  .darker-green-color {
    color: $darker-green;
  }

  .white-color {
    color: white;
  }

  .apex-dark-blue-color {
    tspan {
      color: $dark-blue-color;
      fill: $dark-blue-color;
    }
  }

  .round-input-error {
    border-color: $warning-color !important;
    background-color: $pale-red-color;
  }

  .edit-icon {
    color: $edit-icon-color;
  }

  .check-mark-icon {
    color: $checkmark-icon-color;
  }

  .basic-input {
    border: solid 1px $borders-color;
    border-radius: 4px;
    outline: none;
    padding: 8px;
    padding-left: $input-padding;
    padding-right: $input-padding;
    font-size: 16px;
    font-family: 'Inter', sans-serif;

    &:focus {
      border: 2px solid $dark-blue-color;
    }
  }

  .input-comment {
    height: 112px !important;
    width: 100% !important;
  }

  .break-word {
    word-break: break-word;
  }

  .close-icon {
    @include icon-size(18px);
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .right-add-button-box {
    margin-left: 16px;
  }

  .app-avatar {
    display: unset;
  }

  @include media-query(xxl) {
    .app-avatar {
      display: none;
    }
  }

  .tabs-height {
    height: 40px;
  }
  @include media-query(xxxxl) {
    .tabs-height {
      min-height: 40px;
      height: auto;
    }
  }

  .absolute-positioned-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .section-divider {
    height: 2px;
    width: 100%;
    background-color: $borders-color;
    border-radius: 1px;
    margin-top: $medium-gap;
    margin-bottom: $l-gap;
  }
}

@mixin table-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background-color: $table-scrollbar-thumb-color;
  }

  &::-webkit-scrollbar-track {
    height: min-content;
  }
}

.round-select {
  height: $select-height;

  ::ng-deep .dropdown-trigger button {
    border-radius: 10px !important;
    border: 1px solid #dbdbdb !important;

    mat-icon {
      @include icon-size(20px);
      color: black;
    }
  }
}

.select-height {
  height: $select-height;
}

.info-icon {
  background-color: #1a1a1a;
  color: white;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  font-size: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: serif;
  cursor: default;
}

.empty-state-wrapper {
  padding-top: 15vh;
}

.text-color-basic {
  color: $text-color-basic;
}
