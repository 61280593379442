@mixin font($font-family, $font-file, $weight) {
  @font-face {
    font-family: $font-family;
    src: url($font-file + '.ttf') format('truetype');
    font-weight: $weight;
    font-style: normal;
  }
}

@include font('Inter', '~assets/fonts/Inter-Regular', 400);
@include font('Inter', '~assets/fonts/Inter-Bold', bold);
@include font('Inter', '~assets/fonts/Inter-Medium', 500);
@include font('SF Mono', '~assets/fonts/SFMono-Bold', 700);
@include font('SF Mono', '~assets/fonts/SFMono-Medium', 500);
