@import 'utilities/variables';

div.notification {
  padding: 14px 22px 14px 52px;
  font-size: 16px;
  color: #000 !important;

  .delete {
    top: 12px;
    left: 22px;
    color: #fff;
  }

  &.is-danger {
      background-color: $pale-red-color;
      border: 1px solid $warning-color;

      .delete {
        background-color: $warning-color;
      }
  }

  &.is-success {
    background-color: $pale-green-color;
    border: 1px solid $green-color;

    .delete {
      background-color: $green-color;
      display:inline-block;

      &::after {
        position: absolute;
        width: 40%;
        height: 2px;
        background-color:#fff;
        left: 0;
        bottom: 0;
        transform: translateX(10px) rotate(-45deg);
        transform-origin: left bottom;
      }

      &::before {
        position: absolute;
        width: 2px;
        height: 25%;
        background-color:#fff;
        left: 0px;
        top: 40%;
        transform: translateX(9px) rotate(-45deg);
        transform-origin: left bottom;
      }
    }
  }
}
