@import 'utilities/common';
@import 'utilities/fonts';
@import 'layout/layout_utils';
@import 'utilities/typography';
@import 'themes/theme';
@import 'vendors';
@import 'utilities/checkbox';
@import 'utilities/modal';
@import 'utilities/buttons';

html {
  overflow: auto;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Inter', 'sans-serif';
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #1a1a1a;
}

.transparent-backdrop {
  background: transparent;
}

.wevestr-dialog-container {
  display: flex;
  flex-direction: column;
}

.wevestr-dialog-container .mat-dialog-container {
  border-radius: 8px;
  padding: 0;
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.side-dialog .mat-dialog-container {
  border-radius: 0;
}

dialog {
  border: 1px solid $light-blue !important;
}

// Added to remove the background color of the tooltip from the material components which was updated to the v16
// Though it is not recommended to use !important, it is needed to override the default styles
// This changes was related to task: https://wevestr.atlassian.net/browse/WEVAPP-3639
.mat-mdc-tooltip .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: transparent;
  --mdc-plain-tooltip-supporting-text-color: $text-color-primary;
  padding: 0;
  min-height: 10px;
}

// Added this to display the cutoff select-box in the dialog
// This change is related to task: https://wevestr.atlassian.net/browse/WEVAPP-3673
.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow-y: visible;
}

.info-tooltip,
.mat-tooltip.info-tooltip {
  color: $text-color-primary;
  padding: $m-gap;
  border-radius: 0 $border-radius $border-radius $border-radius;
  @include typography-class($small-font-size, normal, normal, Inter, 0.22px);

  box-shadow: 0 10px 20px 0 $tooltip-shadow-color;
  border: solid 1px $tooltip-border-color;
  background-color: $white-background-color;
  max-width: 230px;

  &.info-tooltip_dark {
    background-color: $tooltip_theme_dark_background_color;
    color: $text-color-secondary;
  }

  &.info-tooltip_no-corner {
    border-radius: $border-radius;
  }

  &.info-tooltip_top-alignment {
    position: relative;
    top: calc(50% - $s-gap);
    margin: 0 $m-gap;
  }

  &.info-tooltip_center-alignment {
    margin: $xs-gap 0;
    position: relative;
    left: calc(50% - $s-gap);
  }

  &.info-tooltip_center-alignment-no-gap {
    margin: 0;
    position: relative;
    left: calc(50% - $s-gap);
  }
}

@include app-common();
@include app-layout-utilities();
@include app-typography();
@include app-modal-common-styles();
@include app-buttons();

@media screen and (max-height: 800px) {
  .cdk-global-overlay-wrapper {
    overflow-y: auto;
  }
}

// Product tour library (driver.js) styles
.driver-popover-next-btn,
.driver-popover-prev-btn {
  border: none !important;
  font-size: 16px !important;
}

.driver-popover-prev-btn {
  color: $pale-grey-brownish !important;
}

.driver-popover-next-btn {
  color: $primary-blue !important;
}
.product-tour-backdrop {
  background: rgba(3, 17, 37, 0.5) !important;
}
